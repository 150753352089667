const contentData = [
  {
    title: "Explore the Galaxy",
    link: "https://example.com/galaxy",
    description:
      "Dive into the mysteries of the cosmos! Learn about different galaxies, stars, and the wonders of the universe."
  },
  {
    title: "Gardening Basics",
    link: "https://example.com/gardening",
    description:
      "Get your hands dirty with our beginner's guide to gardening. Discover tips on growing vegetables, flowers, and herbs."
  },
  {
    title: "Mastering Italian Cuisine",
    link: "https://example.com/italian-cuisine",
    description:
      "Unlock the secrets of Italian cooking with our comprehensive guide. Learn to make classic dishes like pasta, pizza, and risotto."
  },
  {
    title: "Photography Tips for Beginners",
    link: "https://example.com/photography",
    description:
      "Capture the world through your lens. This guide provides essential tips for beginners to improve their photography skills."
  }
];

export default contentData;
