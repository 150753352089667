import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config";

const onRedirectCallback = (appState) => {
  // Log the appState to see what's being returned
  console.log("onRedirectCallback appState:", appState);

  // Determine the correct return URL based on the environment and the current origin
  const returnToUrl = process.env.NODE_ENV === 'production' ? `${window.location.origin}/` : 'http://localhost:3000/';

  // Use the returnTo from appState if available, otherwise use the determined URL
  const path = appState?.returnTo || returnToUrl;

  // Log the path to see what URL is being used for redirection
  console.log("Redirecting to:", path);

  // Perform the redirection
  if (path.startsWith(window.location.origin)) {
    window.history.replaceState({}, document.title, path);
  } else {
    console.error("Mismatched URL origin, cannot redirect:", path);
    // Optionally, handle the mismatch, e.g., redirect to a default path
    window.history.replaceState({}, document.title, '/');
  }
};

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

const root = createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider {...providerConfig}>
    <App />
  </Auth0Provider>
);

serviceWorker.unregister();
