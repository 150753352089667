import React, { useState, useEffect, useCallback } from "react";
import { Alert } from "reactstrap";
import Highlight from "../components/Highlight";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button'; // Importing Button from material-ui

export const ExternalApiComponent = () => {
  const { audience } = getConfig();
  const apiOrigin =
    process.env.NODE_ENV === "production"
      ? "https://www.gazz.io"
      : "http://localhost:8080";

  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
    accessToken: "",
  });

  const { getAccessTokenSilently, loginWithPopup, getAccessTokenWithPopup } =
    useAuth0();

  // Memoize fetchAccessToken using useCallback
  const fetchAccessToken = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      setState((prevState) => ({
        ...prevState,
        accessToken: token,
      }));
    } catch (error) {
      console.error("Error fetching access token", error);
    }
  }, [getAccessTokenSilently]); // Dependency array

  // Call fetchAccessToken when the component mounts
  useEffect(() => {
    fetchAccessToken();
  }, [fetchAccessToken]);

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup();
      setState((prevState) => ({
        ...prevState,
        error: null,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        error: error.error,
      }));
    }

    await callApi();
  };

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
      setState((prevState) => ({
        ...prevState,
        error: null,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        error: error.error,
      }));
    }

    await callApi();
  };

  const callApi = async () => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(`${apiOrigin}/api/external`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const responseData = await response.json();

      setState((prevState) => ({
        ...prevState,
        showResult: true,
        apiMessage: responseData,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        error: error.error,
      }));
    }
  };

  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };

  return (
    <>
      <div className="mb-5">
        {/* Display environment mode */}
        <Alert color="info">
          Environment Mode: <code>{process.env.NODE_ENV || "development"}</code>
        </Alert>

        {state.error === "consent_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleConsent)}
            >
              consent to get access to this application's API
            </a>
          </Alert>
        )}

        {state.error === "login_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleLoginAgain)}
            >
              log in again
            </a>
          </Alert>
        )}

        <h1>Internal API</h1>
        <p className="lead">
          Ping the internal API by clicking the button below.
        </p>

        {/* Display the access token */}
        {state.accessToken && (
          <Alert color="info">
            Your Auth Token: <code>{state.accessToken}</code>
          </Alert>
        )}

        <p>
          This will call an API on port 8080 when run locally or
          gazz.io/api/external in production. An access token is sent as part of
          the request's `Authorization` header and the API will validate it
          using the API's audience value.
        </p>

        {!audience && (
          <Alert color="warning">
            <p>
              You can't call the API at the moment because your application does
              not have any configuration for <code>audience</code>, or it is
              using the default value of <code>YOUR_API_IDENTIFIER</code>. You
              might get this default value if you used the "Download Sample"
              feature of{" "}
              <a href="https://auth0.com/docs/quickstart/spa/react">
                the quickstart guide
              </a>
              , but have not set an API up in your Auth0 Tenant. You can find
              out more information on{" "}
              <a href="https://auth0.com/docs/api">setting up APIs</a> in the
              Auth0 Docs.
            </p>
            <p>
              The audience is the identifier of the API that you want to call
              (see{" "}
              <a href="https://auth0.com/docs/get-started/dashboard/tenant-settings#api-authorization-settings">
                API Authorization Settings
              </a>{" "}
              for more info).
            </p>

            <p>
              In this sample, you can configure the audience in a couple of
              ways:
            </p>
            <ul>
              <li>
                in the <code>src/index.js</code> file
              </li>
              <li>
                by specifying it in the <code>auth_config.json</code> file (see
                the <code>auth_config.json.example</code> file for an example of
                where it should go)
              </li>
            </ul>
            <p>
              Once you have configured the value for <code>audience</code>,
              please restart the app and try to use the "Ping API" button below.
            </p>
          </Alert>
        )}

        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={callApi} disabled={!audience}>
            Ping API
          </Button>
        </Stack>
      </div>

      <div className="result-block-container">
        {state.showResult && (
          <div className="result-block" data-testid="api-result">
            <h6 className="muted">Result</h6>
            <Highlight>
              <span>{JSON.stringify(state.apiMessage, null, 2)}</span>
            </Highlight>
          </div>
        )}
      </div>
    </>
  );
};

export default withAuthenticationRequired(ExternalApiComponent, {
  onRedirecting: () => <Loading />,
});
